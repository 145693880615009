const queryTemplate = {
  '... on Category': [
    'name',
    {
      tags: [
        {
          item: [
            {
              '... on Tag': ['name'],
            },
          ],
        },
      ],
    },
    'categoryHeaderColor',
    'categoryHeaderTextColor',
    {
      categoryHeaderTextFont: ['family'],
    },
  ],
};

export default queryTemplate;
