import { buildPageDataQuery, getDirectusFileUrl } from 'utils/directus';
import { FunnelPageType } from 'constant';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import articleTwoColumnQueryTemplate from 'generic/query-templates/articleTwoColumnQueryTemplate';
import navigationMenuQueryTemplate from 'generic/query-templates/navigationMenuQueryTemplate';
import subscriptionformQueryTemplate from '../generic/query-templates/subscriptionformQueryTemplate';
import claimScrollerQueryTemplate from 'generic/query-templates/claimScrollerQueryTemplate';
import footerQueryTemplate from 'generic/query-templates/footerQueryTemplate';
import { queryTemplateForPolicyPages as styleOverrides } from 'generic/GlobalStyle';
import GlobalStyle from 'generic/GlobalStyle';
import HomePageSections from 'generic/HomePageSections';
import NavigationMenu from 'generic/NavigationMenu';
import Footer from 'generic/Footer';
import { FunnelDataProvider } from 'utils/funnel-data-context';
import { usePageView, PAGE_TYPES } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';
import { ThemeProvider } from 'utils/theme-context';
import DynamicHead from 'components/DynamicHead';
import DynamicBody from 'components/DynamicBody';
import DynamicBottom from 'components/DynamicBottom';
import PreviewModeBanner from 'components/PreviewModeBanner';
import { PreviewDataProvider } from 'utils/preview-data-context';

export const buildPage = () => {
  function HomePage({ preview, pageData, funnelData }) {
    const { title, footer, navigationMenu, socialLinks, favicon, logo, sections, ...styles } =
      pageData;
    const faviconSrc = useMemo(() => getDirectusFileUrl(favicon), [favicon]);
    const { asPath } = useRouter();

    usePageView({ pageType: PAGE_TYPES.HOME_PAGE, path: asPath });
    rudder.usePageView({ pageType: PAGE_TYPES.HOME_PAGE, path: asPath, funnelData });

    return (
      <ThemeProvider theme={funnelData.theme}>
        <PreviewDataProvider preview={preview}>
          <FunnelDataProvider funnelData={funnelData}>
            {preview && <PreviewModeBanner />}
            <GlobalStyle fonts={funnelData.fonts} {...styles} />
            <DynamicBody></DynamicBody>
            <DynamicHead></DynamicHead>
            <Head>
              <title>{title}</title>
              <meta property="og:title" content={title}></meta>
              <meta name="twitter:title" content={title}></meta>
              <link rel="icon" href={faviconSrc} />
            </Head>
            <NavigationMenu
              menuLinks={funnelData.links}
              logo={logo}
              footer={footer}
              socialLinks={socialLinks}
              menuCategories={navigationMenu}
            />
            <HomePageSections sections={sections} />
            <Footer
              {...footer}
              footerLinks={funnelData.links}
              socialLinks={socialLinks}
              navigationLinks={navigationMenu}
            />
            <DynamicBottom></DynamicBottom>
          </FunnelDataProvider>
        </PreviewDataProvider>
      </ThemeProvider>
    );
  }

  return HomePage;
};

export const homePagePageQueryTemplateFields = [
  'id',
  'title',
  {
    favicon: ['...FileParts'],
  },
  {
    logo: ['...FilePartsWithMetadata'],
  },
  {
    navigationMenu: [
      {
        item: [navigationMenuQueryTemplate],
      },
    ],
  },
  {
    sections: [
      'id',
      'collection',
      {
        item: [
          articleTwoColumnQueryTemplate,
          subscriptionformQueryTemplate,
          claimScrollerQueryTemplate,
        ],
      },
    ],
  },
  {
    socialLinks: [
      {
        item: [{ '... on SocialLink': ['name', { icon: ['...FilePartsWithMetadata'] }, 'url'] }],
      },
    ],
  },
  footerQueryTemplate,
  ...styleOverrides,
  'linkColor',
];

export const pageDataQuery = buildPageDataQuery(
  FunnelPageType.HOME_PAGE,
  homePagePageQueryTemplateFields
);
