const queryTemplate = {
  '... on ClaimScroller': [
    {
      claims: [
        {
          item: [
            {
              '... on ClaimText': ['text', { icon: ['...FilePartsWithMetadata'] }],
            },
          ],
        },
      ],
    },
    'backgroundColor',
    'textColor',
    { textFont: ['family'] },
  ],
};

export default queryTemplate;
