import productCardQueryTemplate from './productCardQueryTemplate';
import articlePageQueryTemplate from './articlePageQueryTemplate';

const queryTemplate = {
  '... on ArticleTwoColumn': [
    'id',
    'title',
    {
      leftSections: [
        {
          item: [articlePageQueryTemplate],
        },
      ],
    },
    {
      rightSections: [
        'id',
        'collection',
        {
          item: [articlePageQueryTemplate, productCardQueryTemplate],
        },
      ],
    },
    'backgroundColor',
  ],
};

export default queryTemplate;
