import { buildGetStaticPaths } from 'page-props/funnel-page';
import { buildPage, pageDataQuery } from 'page-builders/home-page';
import { FunnelPageType } from 'constant';
import { buildFullyDynamicGetStaticProps } from 'page-props/content-property-page';

const getStaticProps = buildFullyDynamicGetStaticProps({
  pageDataQuery,
  pageType: FunnelPageType.HOME_PAGE,
});

const getStaticPaths = buildGetStaticPaths({
  funnelPageType: FunnelPageType.HOME_PAGE,
  pageDataQuery,
});

export { getStaticProps, getStaticPaths };

export default buildPage();
